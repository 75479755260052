import React, { Component } from "react";
import FormBottomNav from "../components/FormBottomNav";
import FormTopNav from "../components/FormTopNav";
import PrintFormGroup from "../components/PrintFormGroup";
import LoginPrompt from "../components/LoginPrompt";

import { Row, Col, Container, Button } from "react-bootstrap";
import steps from "../Assets/data/stepsData";
import PricingDetails from "../components/PricingDetails";
import _ from "lodash";

import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../redux/actions/quote";
import { getDiscounts, getPricing, getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import EpicAPI from "../Assets/api/epic";
import GeneralModal from "../components/GeneralModal";
import xmlescape from "xml-escape";
import discounts from "../Assets/data/discountsData";
import axios from "axios";
import ToolTip from "../components/ToolTip";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    discounts: getDiscounts(state),
    pricing: getPricing(state),
  };
};

export class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      Middle: "",
      Last: "",
      email: "",
      location: "",
      manitoba: "",
      startDate: "",
      loginPrompt: false,
      clientError: false,
      isLoading: false,
      prevPage: "/",
      nextPage: "/quote/coverages",
      currentStep: 0,
      coupon: "",
      steps: steps,
      insertedTransactions: false,
      advocisMember: "no",
      // memberID stands for Advocis Member ID, for easier update the existing code
      // advocisMemberId: "",
      memberId: "",
      advocisMemberIdButtonLabel: "",
      greatwayMember: "no",
      greatwayMemberId: "",
      advocisMemberValid: false,
      greatwayMemberValid: false,
      isCheckingAdvocisMemberId: false,
      isCheckingGreatwayMemberId: false,
    };
  }

  componentDidMount = async () => {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else {
      let FirstName = quote.FirstName;
      let Last = quote.Last;
      let email = quote.email;
      let location = quote.location === undefined ? "" : quote.location;
      // log initial location
      // console.log("Initial location is: " + location);
      let startDate = quote.startDate;
      this.setState({ quote, FirstName, Last, email, location, startDate });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { quote, saveQuote, locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    if (!_.isEqual(quote, prevProps.quote)) {
      let FirstName = quote.FirstName;
      let Middle = "";
      if (quote.Middle) {
        Middle = quote.Middle;
      }
      let Last = quote.Last;
      let email = quote.email;

      let location = quote.location === undefined ? "" : quote.location;
      // log selected location
      // console.log("Selected location is: " + location);

      let startDate = quote.startDate;
      let manitoba = quote.manitoba;

      let advocisMember = quote.advocisMember;
      let memberId = quote.memberId;
      let advocisMemberValid = quote.advocisMemberValid;
      let greatwayMember = quote.greatwayMember;
      let greatwayMemberId = quote.greatwayMemberId;
      this.setState({
        quote,
        FirstName,
        Middle,
        Last,
        email,
        location,
        startDate,
        manitoba,
        advocisMember,
        memberId,
        advocisMemberValid,
        greatwayMember,
        greatwayMemberId,
      });
    }
    // console.log(this.state);
  }

  validateForm() {
    // Add debug logging
    console.log("Validation check:");
    console.log("- advocisMember:", this.state.advocisMember);
    console.log("- advocisMemberValid:", this.state.advocisMemberValid);
    console.log("- advocisMemberIdButtonLabel:", this.state.advocisMemberIdButtonLabel);

    const advocisCondition = this.state.advocisMemberValid || (this.state.advocisMember === "no" && this.state.advocisMemberIdButtonLabel !== "✘");

    console.log("- advocisCondition result:", advocisCondition);

    if (
      !!this.state.FirstName &&
      this.state.FirstName.length > 0 &&
      this.state.FirstName.trim().length > 0 &&
      !!this.state.Last &&
      this.state.Last.length > 0 &&
      this.state.Last.trim().length > 0 &&
      !!this.state.email &&
      this.state.email.length > 0 &&
      !!this.state.location &&
      this.state.location.length > 0 &&
      this.state.location !== "default" &&
      !!this.state.startDate &&
      this.state.startDate.length > 0 &&
      this.state.manitoba !== undefined &&
      advocisCondition
    ) {
      // Date validation logic
      const year = this.state.startDate.substring(0, 4);
      const month = this.state.startDate.substring(5, 7);
      const day = this.state.startDate.substring(8, 10);

      const startDate = new Date(year, parseInt(month) - 1, day);

      const localOffset = new Date().getTimezoneOffset();
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + localOffset / 60);

      const now = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

      const dateValid = startDate >= now;
      console.log("- Date validation result:", dateValid);

      return dateValid;
    } else {
      console.log("- Basic validation failed");
      return false;
    }
  }

  // handleChange = (event) => {
  //   this.setState({
  //     [event.target.id]: event.target.value,
  //   });
  // };

  // handleCoupon = (coupon) => {
  //   coupon = this.state.coupon + " " + coupon;
  //   this.setState({ coupon });
  // };

  handleSubmit = async (event) => {
    const { saveQuote } = this.props;
    const {
      email,
      location,
      startDate,
      quote,
      manitoba,
      insertedTransactions,
      advocisMember,
      greatwayMember,
      memberId,
      advocisMemberValid,
      greatwayMemberId,
    } = this.state;

    let clientID = null;

    event.preventDefault();
    this.setState({ isLoading: true });

    const FirstName = this.state.FirstName.trim();
    const Middle = this.state.Middle.trim();
    const Last = this.state.Last.trim();

    this.setState({ FirstName, Middle, Last });

    await EpicAPI.getClient({ FirstName: FirstName, Last: Last, email: xmlescape(email) }, false, true)
      .then((clientInfo) => {
        if (clientInfo && clientInfo.client_id) {
          clientID = clientInfo.client_id;
          if (clientInfo.client_lookup_code) {
            quote.client_lookup_code = clientInfo.client_lookup_code;
          }
          if (clientInfo.account_name) {
            quote.account_name = clientInfo.account_name;
          }
        } else if (clientInfo && _.isArray(clientInfo)) {
          clientID = clientInfo[0].client_id;
        }
      })
      .catch((e) => {
        console.log(e);
      });

    if (!clientID) {
      await EpicAPI.insertClient({
        FirstName: FirstName,
        Last: Last,
        Middle: Middle,
        email: xmlescape(email),
        // pass in location
        location: location,
      })
        .then((client_id) => {
          // console.log(client_id);
          clientID = client_id;
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (!!clientID) {
      try {
        saveQuote({
          ...quote,
          FirstName,
          Middle,
          Last,
          email,
          location,
          startDate,
          clientID,
          manitoba,
          insertedTransactions,
          advocisMember,
          greatwayMember,
          memberId,
          advocisMemberValid,
          greatwayMemberId,
        });
        this.setState({ isLoading: false, clientError: false });
        this.props.history.push(this.state.nextPage);
      } catch (e) {
        console.log(e);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false, clientError: true });
    }
  };

  handleModalClose = () => {
    this.setState({ clientError: false });
  };

  handleAdvocisMemberIdCheck = async () => {
    const { memberId, Last, FirstName } = this.state;
    const { quote, saveQuote } = this.props;

    this.setState({ isCheckingAdvocisMemberId: true, advocisMemberIdButtonLabel: "Checking..." });
    // console.log("Validating Advocis member ID:", memberId, Last, FirstName);

    const proxyUrl = `${window.location.protocol}//${window.location.host}/proxy/index.php`;

    try {
      const response = await fetch(`${proxyUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          Iid: memberId,
          lname: Last,
          uname: FirstName,
        }),
      });

      if (response.ok) {
        const text = await response.text();
        // console.log('response.text', text);

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");
        const validStatusElement = xmlDoc.querySelector("string");
        const validStatusResult = validStatusElement ? validStatusElement.textContent : null;

        console.log("Advocis member ID validation result:", validStatusResult);

        if (validStatusResult === "1") {
          this.setState({
            advocisMemberValid: true,
            advocisMemberIdButtonLabel: "✔",
            advocisMember: "yes",
          });

          saveQuote({
            ...quote,
            advocisMemberValid: true,
            advocisMember: "yes",
            memberId: memberId,
          });
          // console.log('Advocis member ID is valid');
        } else {
          this.setState({ advocisMemberValid: false, advocisMember: "no", advocisMemberIdButtonLabel: "✘" });
          // console.log('Advocis member ID is invalid', validStatusResult);
        }
      } else {
        // console.error('Server returned error:', response.status);
        this.setState({ advocisMemberValid: false, advocisMember: "no", advocisMemberIdButtonLabel: "✘" });
      }
    } catch (error) {
      // console.error('Error validating Advocis member ID:', error);
      this.setState({
        advocisMemberValid: false,
        advocisMember: "no",
        advocisMemberIdButtonLabel: "✘",
      });

      // Update Redux store for failure case
      saveQuote({
        ...quote,
        advocisMemberValid: false,
        advocisMember: "no",
      });
    } finally {
      this.setState({ isCheckingAdvocisMemberId: false });
    }
  };

  // update handleGreatwayMemberIdCheck later when we have the API
  // handleGreatwayMemberIdCheck = async () => {
  //   const { greatwayMemberId } = this.state;
  //   // TODO: add greatway member ID check
  //   this.setState({ isCheckingGreatwayMemberId: true });
  //   console.log(greatwayMemberId);
  // }

  render() {
    const { quote } = this.props;
    const { locale } = this.props;
    const { advocisMemberIdButtonLabel } = this.state;

    const { FirstName, Middle, Last, email, location, steps, startDate, currentStep, isLoading, prevPage, loginPrompt, clientError, manitoba } =
      this.state;
    const provinces = ["Select a Province...", "AB", "BC", "MB", "NB", "NL", "NS", "NU", "NT", "ON", "PE", "QC", "SK", "YT"];
    let quoteForm = [
      {
        controlId: "FirstName",
        formLabel: "What's your first name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: FirstName,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "Middle",
        formLabel: "What's your middle name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: Middle,
            placeholder: "",
            maxLength: "16",
          },
        ],
      },
      {
        controlId: "Last",
        formLabel: "What's your last name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: Last,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "email",
        formLabel: "What's your email?",
        FormControl: [
          {
            autoFocus: false,
            type: "email",
            value: email,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "location",
        formLabel: "Where is your Province of Residence?",
        FormControl: [
          {
            autoFocus: false,
            type: "select",
            value: location,
            options: provinces,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "manitoba",
        formLabel: "Are you licensed to sell products in Manitoba?",
        FormControl: [
          {
            autoFocus: false,
            type: "radios",
            //TODO: update to No or Yes?
            value: manitoba,
            options: ["No", "Yes"],
            placeholder: "",
          },
        ],
      },
      {
        advocisMember: [
          {
            details: [
              {
                controlId: "advocisMember",
                formLabel: "Are you a current Advocis member?",
                FormControl: [
                  {
                    type: "radios",
                    value: this.state.advocisMember,
                    options: ["no", "yes"],
                    dependencies: [
                      {
                        condition: "yes",
                        controlId: "memberId",
                        formLabel: "Enter your Advocis Member ID:",
                        FormControl: [
                          {
                            autoFocus: false,
                            type: "textButtonCombined",
                            value: this.state.memberId,
                            placeholder: "",
                            buttonText: "Check",
                            buttonLabel: this.state.advocisMemberIdButtonLabel,
                            buttonControlId: "advocisMemberIdCheck",
                            buttonOnClick: this.handleAdvocisMemberIdCheck,
                            buttonDisabled: this.state.isCheckingAdvocisMemberId,
                          },
                        ],
                      },
                    ],
                    toolTip: [
                      {
                        text: "The Advocis membership discount for individual E&O insurance does not stack on top of any other available discounts.",
                        origin: "horizontal",
                        vertical: "top",
                        horizontal: "left",
                        learnMoreText: "Learn More",
                      },
                    ],
                  },
                ],
                toolTip: [
                  {
                    text: "The Advocis membership discount for individual E&O insurance does not stack on top of any other available discounts.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                  },
                ],
              },
            ],
          },
        ],
      },
      // Greatway member ID check
      // {
      //   ...discounts[0]["advocisMember"][0]["details"][0],
      //   FormControl: [
      //     {
      //       ...discounts[0]["advocisMember"][0]["details"][0].FormControl[0],
      //       dependencies: [
      //         {
      //           ...discounts[0]["advocisMember"][0]["details"][0].FormControl[0].dependencies[0],
      //           FormControl: [
      //             {
      //               ...discounts[0]["advocisMember"][0]["details"][0].FormControl[0].dependencies[0].FormControl[0],
      //               buttonOnClick: this.handleAdvocisMemberIdCheck,
      //               buttonDisabled: this.state.isCheckingAdvocisMemberId,
      //             }
      //           ]
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   controlId: "greatwayMember",
      //   formLabel: "Are you a Greatway Member?",
      //   FormControl: [{
      //     type: "radios",
      //     value: 'No',
      //     options: ["No", "Yes"],
      //     dependencies: [
      //       {
      //         condition: "Yes",
      //         controlId: "greatwayMemberId",
      //         formLabel: "Enter your Greatway Member ID:",
      //         FormControl: [{
      //           autoFocus: false,
      //           type: "textButtonCombined",
      //           value: "",
      //           placeholder: "",
      //           buttonText: "Check",
      //           buttonControlId: "greatwayMemberIdCheck",
      //           buttonOnClick: this.handleGreatwayMemberIdCheck,
      //           buttonDisabled: this.state.isCheckingGreatwayMemberId,
      //         }]
      //       }
      //     ]
      //   }]
      // },
      {
        controlId: "startDate",
        formLabel: "When do you need your coverage to start?",
        FormControl: [
          {
            required: true,
            type: "date",
            value: startDate,
            placeholder: "",
          },
        ],
      },
    ];
    //TODO: doesn't validate 60 days in future, just sets max that can be edited out.
    return (
      <Container className={`MainQuote GetQuote ${"quote" + locale}`} as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            {loginPrompt ? <LoginPrompt link="/login" /> : null}
            <FormTopNav steps={steps} currentStep={currentStep} />
            <div className="formDescription Line Green bottom">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Get a Quote")}</h1>
                <PricingDetails />
              </div>
              <h3>
                {translateString(locale, "Hi")} {!(FirstName === "") ? FirstName : "there"},
              </h3>
              {!!quote && quote.renewal && (
                <p>{translateString(locale, "We’re going to help you get a renewal for E&O coverage as quickly as possible!")}</p>
              )}
              {(!quote || !quote.renewal) && (
                <p>{translateString(locale, "We’re going to help you get a quote for E&O coverage as quickly as possible!")}</p>
              )}
              <p>
                {translateString(
                  locale,
                  "To start, we just need a few things from you. Fill out the simple questions below and we’ll get you set up in no time at all."
                )}
              </p>
            </div>
            <form onSubmit={this.handleSubmit}>
              <PrintFormGroup parent="quote" data={quoteForm} />
              <FormBottomNav
                disabled={!this.validateForm()}
                isLoading={isLoading}
                prevText={translateString(locale, "Start Over")}
                prevPage={prevPage}
                className="Line Green top"
                required={["First Name", "Last Name", "Email", "Province", "Start date of coverage", "Membership Validation"]}
              />
            </form>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar"></Col>
        </Row>
        <GeneralModal show={clientError}>
          <h2>{translateString(locale, "Client Error")}</h2>
          <p>{translateString(locale, "We were unable to find or create a client record.")}</p>
          <p>{translateString(locale, "Please try again.")}</p>
          <Button
            variant="primary"
            onClick={() => {
              this.handleModalClose();
            }}>
            {translateString(locale, "OK")}
          </Button>
        </GeneralModal>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
})(Quote);
