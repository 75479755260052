import _ from "lodash";
import moment from "moment";

export const loadPricing = (quote, pricing) => {
  console.log("passed in quote and pricing", quote, pricing);
  if (!quote || _.isEmpty(quote)) return [];

  let prices = [];
  let includeCyber = false;
  let manitoba = quote.manitoba === "Yes";

  if (quote.lifeInsurance && quote.lifeInsurancePrice) {
    let type, base, zur, per, agg;
    let label = "Life Insurance / Mutual Funds - E&O";
    // let label = 'Life Insurance';
    let limit = quote.lifeInsurancePrice;
    // console.log({quote, pricing});
    switch (quote.lifeInsurancePrice) {
      case 1000000:
        type = manitoba ? "EA1MPer" : "EA1Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA1MAgg" : "EA1Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA1MBase" : "EA1Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA1MZur" : "EA1Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      case 2000000:
        type = manitoba ? "EA2MPer" : "EA2Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA2MAgg" : "EA2Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA2MBase" : "EA2Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA2MZur" : "EA2Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      case 3000000:
        type = manitoba ? "EA3MPer" : "EA3Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA3MAgg" : "EA3Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA3MBase" : "EA3Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA3MZur" : "EA3Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      case 4000000:
        type = manitoba ? "EA4MPer" : "EA4Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA4MAgg" : "EA4Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA4MBase" : "EA4Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA4MZur" : "EA4Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      case 5000000:
        type = manitoba ? "EA5MPer" : "EA5Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA5MAgg" : "EA5Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA5MBase" : "EA5Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA5MZur" : "EA5Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      case 10000000:
        type = "EA10Per";
        per = _.find(pricing.ea, (i) => i.type === type);
        type = "EA10Agg";
        agg = _.find(pricing.ea, (i) => i.type === type);
        type = "EA10Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        type = "EA10Zur";
        zur = _.find(pricing.ea, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      //includeCyber = true;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }

  if (quote.lifeInsuranceLite && quote.lifeInsuranceLitePrice) {
    let type, base, zur, per, agg;
    let label = "Life Insurance / Mutual Funds - E&O Lite";
    // let label = 'Life Insurance';
    let limit = quote.lifeInsuranceLitePrice;
    // console.log({quote, pricing});
    switch (quote.lifeInsuranceLitePrice) {
      case 1000000:
        type = manitoba ? "A1LMPer" : "A1LPer";
        per = _.find(pricing.a1, (i) => i.type === type);
        type = manitoba ? "A1LMAgg" : "A1LAgg";
        agg = _.find(pricing.a1, (i) => i.type === type);
        type = manitoba ? "A1LMBase" : "A1LBase";
        base = _.find(pricing.a1, (i) => i.type === type);
        type = manitoba ? "A1LMZur" : "A1LZur";
        zur = _.find(pricing.a1, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      //includeCyber = true;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }

  if (quote.iiroc && quote.iirocPrice) {
    let type, base, zur, per, agg;
    let label = "IIROC Licensees (Securities/Mutual Funds)";
    let limit = quote.iirocPrice;
    switch (quote.iirocPrice) {
      case 1000000:
        type = "EB1Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB1Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB1Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB1Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      case 2000000:
        type = "EB2Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB2Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB2Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB2Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      case 3000000:
        type = "EB3Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB3Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB3Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB3Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      case 4000000:
        type = "EB4Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB4Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB4Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB4Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      case 5000000:
        type = "EB5Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB5Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB5Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB5Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      case 10000000:
        type = "EB10Per";
        per = _.find(pricing.eb, (i) => i.type === type);
        type = "EB10Agg";
        agg = _.find(pricing.eb, (i) => i.type === type);
        type = "EB10Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        type = "EB10Zur";
        zur = _.find(pricing.eb, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      //includeCyber = true;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }
  if (quote.unlicensedPlanners && quote.unlicensedPlannersPrice) {
    let type, base, zur, per, agg;
    let label = "Unlicensed Planners/Fee for Service";
    let limit = quote.unlicensedPlannersPrice;
    switch (quote.unlicensedPlannersPrice) {
      case 1000000:
        type = "EC1Per";
        per = _.find(pricing.ec, (i) => i.type === type);
        type = "EC1Agg";
        agg = _.find(pricing.ec, (i) => i.type === type);
        type = "EC1Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        type = "EC1Zur";
        zur = _.find(pricing.ec, (i) => i.type === type);
        break;
      case 2000000:
        type = "EC2Per";
        per = _.find(pricing.ec, (i) => i.type === type);
        type = "EC2Agg";
        agg = _.find(pricing.ec, (i) => i.type === type);
        type = "EC2Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        type = "EC2Zur";
        zur = _.find(pricing.ec, (i) => i.type === type);
        break;
      case 3000000:
        type = "EC3Per";
        per = _.find(pricing.ec, (i) => i.type === type);
        type = "EC3Agg";
        agg = _.find(pricing.ec, (i) => i.type === type);
        type = "EC3Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        type = "EC3Zur";
        zur = _.find(pricing.ec, (i) => i.type === type);
        break;
      case 4000000:
        type = "EC4Per";
        per = _.find(pricing.ec, (i) => i.type === type);
        type = "EC4Agg";
        agg = _.find(pricing.ec, (i) => i.type === type);
        type = "EC4Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        type = "EC4Zur";
        zur = _.find(pricing.ec, (i) => i.type === type);
        break;
      case 5000000:
        type = "EC5Per";
        per = _.find(pricing.ec, (i) => i.type === type);
        type = "EC5Agg";
        agg = _.find(pricing.ec, (i) => i.type === type);
        type = "EC5Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        type = "EC5Zur";
        zur = _.find(pricing.ec, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      //includeCyber = true;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }
  if (quote.licensedAdmin && quote.licensedAdminPrice) {
    let type, base, zur, per, agg;
    let label = "Licensed Administrative Assistants";
    let limit = quote.licensedAdminPrice;
    switch (quote.licensedAdminPrice) {
      case 1000000:
        type = manitoba ? "ED1MPer" : "ED1Per";
        per = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED1MAgg" : "ED1Agg";
        agg = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED1MBase" : "ED1Base";
        base = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED1MZur" : "ED1Zur";
        zur = _.find(pricing.ed, (i) => i.type === type);
        break;
      case 2000000:
        type = manitoba ? "ED2MPer" : "ED2Per";
        per = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED2MAgg" : "ED2Agg";
        agg = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED2MBase" : "ED2Base";
        base = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED2MZur" : "ED2Zur";
        zur = _.find(pricing.ed, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }
  if (quote.directorsAndOfficers && quote.directorsAndOfficersPrice) {
    let type, base, zur;
    let label = "Directors and Officers";
    let limit = quote.directorsAndOfficersPrice;
    switch (quote.directorsAndOfficersPrice) {
      case 500000:
        type = "DO1Base";
        base = _.find(pricing.do, (i) => i.type === type);
        type = manitoba ? "DO1MZur" : "DO1Zur";
        zur = _.find(pricing.do, (i) => i.type === type);
        break;
      case 1000000:
        type = "DO2Base";
        base = _.find(pricing.do, (i) => i.type === type);
        type = manitoba ? "DO2MZur" : "DO2Zur";
        zur = _.find(pricing.do, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      //includeCyber = true;
      let zurich = zur.value ?? 0;
      prices.push({ label, amount, limit, zurich, per: "", agg: "" });
    }
  }
  if (quote.cyberPrivacy) {
    let type, base, zur, per, agg;
    let label = "Cyber/Privacy and Network";
    let limit = quote.cyberPrivacyPrice;
    switch (quote.cyberPrivacyPrice) {
      case 25000:
        type = "SP1Per";
        per = _.find(pricing.sp, (i) => i.type === type);
        type = "SP1Agg";
        agg = _.find(pricing.sp, (i) => i.type === type);
        type = "SP1Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        type = "SP1Zur";
        zur = _.find(pricing.sp, (i) => i.type === type);
        break;
      case 50000:
        type = "SP2Per";
        per = _.find(pricing.sp, (i) => i.type === type);
        type = "SP2Agg";
        agg = _.find(pricing.sp, (i) => i.type === type);
        type = "SP2Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        type = "SP2Zur";
        zur = _.find(pricing.sp, (i) => i.type === type);
        break;
      case 100000:
        type = "SP3Per";
        per = _.find(pricing.sp, (i) => i.type === type);
        type = "SP3Agg";
        agg = _.find(pricing.sp, (i) => i.type === type);
        type = "SP3Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        type = "SP3Zur";
        zur = _.find(pricing.sp, (i) => i.type === type);
        break;
      case 250000:
        type = "SP4Per";
        per = _.find(pricing.sp, (i) => i.type === type);
        type = "SP4Agg";
        agg = _.find(pricing.sp, (i) => i.type === type);
        type = "SP4Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        type = "SP4Zur";
        zur = _.find(pricing.sp, (i) => i.type === type);
        break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      if (includeCyber && quote.cyberPrivacyPrice === 25000) {
        amount = 0;
      }
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }

  if (quote.a1l && quote.a1lPrice) {
    console.log("A1L..........", quote.a1lPrice);
    let type, base, zur, per, agg;
    let label = "A1L - E & O Coverage A Lite";
    let limit = quote.a1lPrice;
    switch (quote.a1lPrice) {
      case 1000000:
        type = manitoba ? "A1LMPer" : "A1LPer";
        per = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "A1LMAgg" : "A1LAgg";
        agg = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "A1LMBase" : "A1LBase";
        base = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "A1LMZur" : "A1LZur";
        zur = _.find(pricing.ed, (i) => i.type === type);
        break;
      // case 2000000:
      //   type = manitoba ? 'A1L2MPer' : 'A1L2Per';
      //   per = _.find(pricing.ed, (i) => i.type === type);
      //   type = manitoba ? 'A1L2MAgg' : 'A1L2Agg';
      //   agg = _.find(pricing.ed, (i) => i.type === type);
      //   type = manitoba ? 'A1L2MBase' : 'A1L2Base';
      //   base = _.find(pricing.ed, (i) => i.type === type);
      //   type = manitoba ? 'A1L2MZur' : 'A1L2Zur';
      //   zur = _.find(pricing.ed, (i) => i.type === type);
      //   break;
      default:
        break;
    }
    if (base) {
      let amount = base.value;
      let zurich = zur.value ?? 0;
      prices.push({ label, amount, limit, zurich, per: per.value ?? "", agg: agg.value ?? "" });
    }
  }

  // if (quote.a1lm && quote.a1lmPrice) {
  //   // console.log('A1LM..........', quote.a1lmPrice);
  //   let type, base, zur, per, agg;
  //   let label = 'A1LM - E & O Coverage A Lite';
  //   let limit = quote.a1lmPrice;
  //   switch (quote.a1lmPrice) {
  //     case 1000000:
  //       type = manitoba ? 'A1LMMPer' : 'A1LM1Per';
  //       per = _.find(pricing.ed, (i) => i.type === type);
  //       type = manitoba ? 'A1LM1MAgg' : 'A1LM1Agg';
  //       agg = _.find(pricing.ed, (i) => i.type === type);
  //       type = manitoba ? 'A1LM1MBase' : 'A1LM1Base';
  //       base = _.find(pricing.ed, (i) => i.type === type);
  //       type = manitoba ? 'A1LM1MZur' : 'A1LM1Zur';
  //       zur = _.find(pricing.ed, (i) => i.type === type);
  //       break;
  //     // case 2000000:
  //     //   type = manitoba ? 'A1LM2MPer' : 'A1LM2Per';
  //     //   per = _.find(pricing.ed, (i) => i.type === type);
  //     //   type = manitoba ? 'A1LM2MAgg' : 'A1LM2Agg';
  //     //   agg = _.find(pricing.ed, (i) => i.type === type);
  //     //   type = manitoba ? 'A1LM2MBase' : 'A1LM2Base';
  //     //   base = _.find(pricing.ed, (i) => i.type === type);
  //     //   type = manitoba ? 'A1LM2MZur' : 'A1LM2Zur';
  //     //   zur = _.find(pricing.ed, (i) => i.type === type);
  //     //   break;
  //     default:
  //       break;
  //   }
  //   if (base) {
  //     let amount = base.value;
  //     let zurich = zur.value ?? 0;
  //     prices.push({ label, amount, limit, zurich, per: per.value ?? '', agg: agg.value ?? '' });
  //   }
  // }

  console.log("Prices..........", prices);

  return prices;
};

export const loadPerClaimAggregate = (policy, pricing) => {
  if (!policy || _.isEmpty(policy) || !policy.code) return [];

  let prices, coverage;

  // console.log('Policy in loadPerClaimAggregate',{ policy });

  switch (policy.code.substr(0, 2)) {
    case "EA":
      prices = pricing.ea;
      coverage = "EO";
      break;
    case "EB":
      prices = pricing.eb;
      coverage = "EO";
      break;
    case "EC":
      prices = pricing.ec;
      coverage = "EO";
      break;
    case "ED":
      prices = pricing.ed;
      coverage = "EO";
      break;
    case "SP":
      prices = pricing.sp;
      coverage = "Cyber";
      break;
    case "A1":
      prices = pricing.a1;
      coverage = "EO";
      break;
    default:
      break;
  }

  if (!prices) return { per: "", agg: "", coverage: "" };

  let per, agg, type;
  type = policy.code + "Per";
  per = _.find(prices, (i) => i.type === type);
  type = policy.code + "Agg";
  agg = _.find(prices, (i) => i.type === type);

  return { per: per.value ?? "", agg: agg.value ?? "", coverage };
};

export const getPricingSplits = (quote, pricing) => {
  if (!quote || _.isEmpty(quote)) return [];
  if (!pricing || _.isEmpty(pricing)) return [];

  let manitoba = quote.location === "MB";
  let splits = {
    ea: {},
    eb: {},
    ec: {},
    ed: {},
    do: {},
    sp: {},
    a1: {},
  };

  // console.log({pricing});
  if (quote.lifeInsurance && quote.lifeInsurancePrice) {
    let _zur = 0,
      _dfc = 0,
      base = 0,
      type;
    switch (quote.lifeInsurancePrice) {
      case 1000000:
        type = manitoba ? "EA1MBase" : "EA1Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA1MZur" : "EA1Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA1MDFC" : "EA1DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      case 2000000:
        type = manitoba ? "EA2MBase" : "EA2Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA2MZur" : "EA2Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA2MDFC" : "EA2DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      case 3000000:
        type = manitoba ? "EA3MBase" : "EA3Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA3MZur" : "EA3Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA3MDFC" : "EA3DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      case 4000000:
        type = manitoba ? "EA4MBase" : "EA4Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA4MZur" : "EA4Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA4MDFC" : "EA4DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      case 5000000:
        type = manitoba ? "EA5MBase" : "EA5Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA5MZur" : "EA5Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA5MDFC" : "EA5DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      case 10000000:
        type = manitoba ? "EA10Base" : "EA10Base";
        base = _.find(pricing.ea, (i) => i.type === type);
        if (base) splits.ea.base = base.value;
        type = manitoba ? "EA10Zur" : "EA10Zur";
        _zur = _.find(pricing.ea, (i) => i.type === type);
        type = manitoba ? "EA10DFC" : "EA10DFC";
        _dfc = _.find(pricing.ea, (i) => i.type === type);
        if (_zur) splits.ea.zurich = _zur.value;
        if (_dfc) splits.ea.dfc = _dfc.value;
        break;
      default:
        break;
    }
  }

  if (quote.lifeInsuranceLite && quote.lifeInsuranceLitePrice) {
    let _zur = 0,
      _dfc = 0,
      base = 0,
      type;
    switch (quote.lifeInsurancePrice) {
      case 1000000:
        type = manitoba ? "A1LMBase" : "A1LBase";
        base = _.find(pricing.a1, (i) => i.type === type);
        if (base) splits.a1.base = base.value;
        type = manitoba ? "A1LMZur" : "A1LZur";
        _zur = _.find(pricing.a1, (i) => i.type === type);
        type = manitoba ? "A1LMDFC" : "A1LDFC";
        _dfc = _.find(pricing.a1, (i) => i.type === type);
        if (_zur) splits.a1.zurich = _zur.value;
        if (_dfc) splits.a1.dfc = _dfc.value;
        break;
      default:
        break;
    }
  }

  if (quote.iiroc && quote.iirocPrice) {
    let _zur = 0,
      _dfc = 0,
      base = 0,
      type;
    switch (quote.iirocPrice) {
      case 1000000:
        type = manitoba ? "EB1MBase" : "EB1Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB1MZur" : "EB1Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB1MDFC" : "EB1DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      case 2000000:
        type = manitoba ? "EB2MBase" : "EB2Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB2MZur" : "EB2Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB2MDFC" : "EB2DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      case 3000000:
        type = manitoba ? "EB3MBase" : "EB3Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB3MZur" : "EB3Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB3MDFC" : "EB3DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      case 4000000:
        type = manitoba ? "EB4MBase" : "EB4Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB4MZur" : "EB4Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB4MDFC" : "EB4DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      case 5000000:
        type = manitoba ? "EB5MBase" : "EB5Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB5MZur" : "EB5Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB5MDFC" : "EB5DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      case 10000000:
        type = manitoba ? "EB10MBase" : "EB10Base";
        base = _.find(pricing.eb, (i) => i.type === type);
        if (base) splits.eb.base = base.value;
        type = manitoba ? "EB10MZur" : "EB10Zur";
        _zur = _.find(pricing.eb, (i) => i.type === type);
        type = manitoba ? "EB10MDFC" : "EB10DFC";
        _dfc = _.find(pricing.eb, (i) => i.type === type);
        if (_zur) splits.eb.zurich = _zur.value;
        if (_dfc) splits.eb.dfc = _dfc.value;
        break;
      default:
        break;
    }
  }
  if (quote.unlicensedPlanners && quote.unlicensedPlannersPrice) {
    let _zur = 0,
      _dfc = 0,
      base = 0,
      type;
    switch (quote.unlicensedPlannersPrice) {
      case 1000000:
        type = manitoba ? "EC1MBase" : "EC1Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        if (base) splits.ec.base = base.value;
        type = manitoba ? "EC1MZur" : "EC1Zur";
        _zur = _.find(pricing.ec, (i) => i.type === type);
        type = manitoba ? "EC1MDFC" : "EC1DFC";
        _dfc = _.find(pricing.ec, (i) => i.type === type);
        if (_zur) splits.ec.zurich = _zur.value;
        if (_dfc) splits.ec.dfc = _dfc.value;
        break;
      case 2000000:
        type = manitoba ? "EC2MBase" : "EC2Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        if (base) splits.ec.base = base.value;
        type = manitoba ? "EC2MZur" : "EC2Zur";
        _zur = _.find(pricing.ec, (i) => i.type === type);
        type = manitoba ? "EC2MDFC" : "EC2DFC";
        _dfc = _.find(pricing.ec, (i) => i.type === type);
        if (_zur) splits.ec.zurich = _zur.value;
        if (_dfc) splits.ec.dfc = _dfc.value;
        break;
      case 3000000:
        type = manitoba ? "EC3MBase" : "EC3Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        if (base) splits.ec.base = base.value;
        type = manitoba ? "EC3MZur" : "EC3Zur";
        _zur = _.find(pricing.ec, (i) => i.type === type);
        type = manitoba ? "EC3MDFC" : "EC3DFC";
        _dfc = _.find(pricing.ec, (i) => i.type === type);
        if (_zur) splits.ec.zurich = _zur.value;
        if (_dfc) splits.ec.dfc = _dfc.value;
        break;
      case 4000000:
        type = manitoba ? "EC4MBase" : "EC4Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        if (base) splits.ec.base = base.value;
        type = manitoba ? "EC4MZur" : "EC4Zur";
        _zur = _.find(pricing.ec, (i) => i.type === type);
        type = manitoba ? "EC4MDFC" : "EC4DFC";
        _dfc = _.find(pricing.ec, (i) => i.type === type);
        if (_zur) splits.ec.zurich = _zur.value;
        if (_dfc) splits.ec.dfc = _dfc.value;
        break;
      case 5000000:
        type = manitoba ? "EC5MBase" : "EC5Base";
        base = _.find(pricing.ec, (i) => i.type === type);
        if (base) splits.ec.base = base.value;
        type = manitoba ? "EC5MZur" : "EC5Zur";
        _zur = _.find(pricing.ec, (i) => i.type === type);
        type = manitoba ? "EC5MDFC" : "EC5DFC";
        _dfc = _.find(pricing.ec, (i) => i.type === type);
        if (_zur) splits.ec.zurich = _zur.value;
        if (_dfc) splits.ec.dfc = _dfc.value;
        break;
      default:
        break;
    }
  }
  if (quote.licensedAdmin && quote.licensedAdminPrice) {
    let _zur = 0,
      _dfc = 0,
      base = 0,
      type;
    switch (quote.licensedAdminPrice) {
      case 1000000:
        type = manitoba ? "ED1MBase" : "ED1Base";
        base = _.find(pricing.ed, (i) => i.type === type);
        if (base) splits.ed.base = base.value;
        type = manitoba ? "ED1MZur" : "ED1MZur";
        _zur = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED1MDFC" : "ED1DFC";
        _dfc = _.find(pricing.ed, (i) => i.type === type);
        if (_zur) splits.ed.zurich = _zur.value;
        if (_dfc) splits.ed.dfc = _dfc.value;
        break;
      case 2000000:
        type = manitoba ? "ED2MBase" : "ED2Base";
        base = _.find(pricing.ed, (i) => i.type === type);
        if (base) splits.ed.base = base.value;
        type = manitoba ? "ED2MZur" : "ED2Zur";
        _zur = _.find(pricing.ed, (i) => i.type === type);
        type = manitoba ? "ED2MDFC" : "ED2DFC";
        _dfc = _.find(pricing.ed, (i) => i.type === type);
        if (_zur) splits.ed.zurich = _zur.value;
        if (_dfc) splits.ed.dfc = _dfc.value;
        break;
      default:
        break;
    }
  }
  if (quote.directorsAndOfficers && quote.directorsAndOfficersPrice) {
    let _zur = 0,
      base = 0,
      type;
    switch (quote.directorsAndOfficersPrice) {
      case 500000:
        type = manitoba ? "DO1MBase" : "DO1Base";
        base = _.find(pricing.do, (i) => i.type === type);
        if (base) splits.do.base = base.value;
        type = "DO1Zur";
        _zur = _.find(pricing.do, (i) => i.type === type);
        if (_zur) splits.do.zurich = _zur.value;
        break;
      case 1000000:
        type = manitoba ? "DO2MBase" : "DO2Base";
        base = _.find(pricing.do, (i) => i.type === type);
        if (base) splits.do.base = base.value;
        type = "DO2Zur";
        _zur = _.find(pricing.do, (i) => i.type === type);
        if (_zur) splits.do.zurich = _zur.value;
        break;
      default:
        break;
    }
  }
  if (quote.cyberPrivacy) {
    let _zur = 0,
      base = 0,
      type;
    switch (quote.cyberPrivacyPrice) {
      case 25000:
        type = "SP1Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        if (base) splits.sp.base = base.value;
        type = "SP1Zur";
        _zur = _.find(pricing.sp, (i) => i.type === type);
        if (_zur) splits.sp.zurich = _zur.value;
        break;
      case 50000:
        type = "SP2Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        if (base) splits.sp.base = base.value;
        type = "SP2Zur";
        _zur = _.find(pricing.sp, (i) => i.type === type);
        if (_zur) splits.sp.zurich = _zur.value;
        break;
      case 100000:
        type = "SP3Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        if (base) splits.sp.base = base.value;
        type = "SP3Zur";
        _zur = _.find(pricing.sp, (i) => i.type === type);
        if (_zur) splits.sp.zurich = _zur.value;
        break;
      case 250000:
        type = "SP4Base";
        base = _.find(pricing.sp, (i) => i.type === type);
        if (base) splits.sp.base = base.value;
        type = "SP4Zur";
        _zur = _.find(pricing.sp, (i) => i.type === type);
        if (_zur) splits.sp.zurich = _zur.value;
        break;
      default:
        break;
    }
  }
  return splits;
};

export const reversePolicyLookup = (policyCode) => {
  let lower = 0;
  let upper = 0;
  let policy_type = "";
  let quoteCoverageObject = {
    lifeInsurance: false,
    lifeInsuranceLite: false,
    iiroc: false,
    unlicensedPlanners: false,
    licensedAdmin: false,
    directorsAndOfficers: false,
    cyberPrivacy: false,
  };
  switch (policyCode) {
    case "EA1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA1M":
      lower = 1000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "A1L":
      lower = 1000000;
      upper = 2000000;
      policy_type = "Life insurance/Mutual Funds Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        lifeInsuranceLite: true,
        lifeInsuranceLitePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "A1LM":
      lower = 1000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        lifeInsuranceLite: true,
        lifeInsuranceLitePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA2":
      lower = 2000000;
      upper = 3000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA2M":
      lower = 2000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA3":
      lower = 3000000;
      upper = 4000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA3M":
      lower = 3000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA4":
      lower = 4000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA4M":
      lower = 4000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA5":
      lower = 5000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA5M":
      lower = 5000000;
      upper = 5000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EA10":
      lower = 5000000;
      upper = 10000000;
      policy_type = "Life insurance/Mutual Funds";
      quoteCoverageObject = {
        lifeInsurance: true,
        lifeInsurancePrice: lower,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB2":
      lower = 2000000;
      upper = 3000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB3":
      lower = 3000000;
      upper = 4000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB4":
      lower = 4000000;
      upper = 5000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB5":
      lower = 5000000;
      upper = 5000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EB10":
      lower = 5000000;
      upper = 10000000;
      policy_type = "IIROC Licensees";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: true,
        iirocPrice: lower,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EC1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "Unlicensed Planners Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: true,
        unlicensedPlannersPrice: lower,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EC2":
      lower = 2000000;
      upper = 3000000;
      policy_type = "Unlicensed Planners Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: true,
        unlicensedPlannersPrice: lower,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EC3":
      lower = 3000000;
      upper = 4000000;
      policy_type = "Unlicensed Planners Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: true,
        unlicensedPlannersPrice: lower,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EC4":
      lower = 4000000;
      upper = 5000000;
      policy_type = "Unlicensed Planners Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: true,
        unlicensedPlannersPrice: lower,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "EC5":
      lower = 5000000;
      upper = 5000000;
      policy_type = "Unlicensed Planners Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: true,
        unlicensedPlannersPrice: lower,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: 25000,
      };
      break;
    case "ED1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "Licensed Admin Assistants Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "ED1M":
      lower = 1000000;
      upper = 5000000;
      policy_type = "Licensed Admin Assistants Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "ED2":
      lower = 2000000;
      upper = 2000000;
      policy_type = "Licensed Admin Assistants Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "ED2M":
      lower = 2000000;
      upper = 5000000;
      policy_type = "Licensed Admin Assistants Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "SP1":
      lower = 25000;
      upper = 25000;
      policy_type = "Cyber Privacy and Network Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: lower,
      };
      break;
    case "SP2":
      lower = 50000;
      upper = 50000;
      policy_type = "Cyber Privacy and Network Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: lower,
      };
      break;
    case "SP3":
      lower = 100000;
      upper = 100000;
      policy_type = "Cyber Privacy and Network Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: lower,
      };
      break;
    case "SP4":
      lower = 250000;
      upper = 250000;
      policy_type = "Cyber Privacy and Network Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: false,
        cyberPrivacy: true,
        cyberPrivacyPrice: lower,
      };
      break;
    case "DO1":
      lower = 500000;
      upper = 500000;
      policy_type = "Directors and Officers Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: true,
        cyberPrivacy: true,
        directorsAndOfficersPrice: lower,
      };
      break;
    case "DO2":
      lower = 1000000;
      upper = 1000000;
      policy_type = "Directors and Officers Coverage";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: false,
        directorsAndOfficers: true,
        cyberPrivacy: true,
        directorsAndOfficersPrice: lower,
      };
      break;
    // add A1L, A1LM

    case "A1L1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "A1L - E & O Coverage A Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "A1L2":
      lower = 2000000;
      upper = 5000000;
      policy_type = "A1L - E & O Coverage A Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "A1LM1":
      lower = 1000000;
      upper = 2000000;
      policy_type = "A1LM - E & O Coverage A Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;
    case "A1LM2":
      lower = 2000000;
      upper = 5000000;
      policy_type = "A1LM - E & O Coverage A Lite";
      quoteCoverageObject = {
        lifeInsurance: false,
        iiroc: false,
        unlicensedPlanners: false,
        licensedAdmin: true,
        licensedAdminPrice: lower,
        directorsAndOfficers: false,
        cyberPrivacy: false,
      };
      break;

    default:
      break;
  }
  return { policy_type, lower, upper, quoteCoverageObject };
};

export const currencyFormat = (num, locale, decimals = 2) => {
  if (locale === "EN") return "$ " + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  else
    return (
      num
        .toFixed(decimals)
        .replace(/\./g, ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " $"
    );
};

export const calculateDiscount = (quote, zurich_total, dfcf_total, a1Object = null) => {
  let licensedDiscount = 0;
  let memberDiscount = 0;
  let couponDiscount = 0;
  let couponZurichDiscount = 0;
  let couponDFCFDiscount = 0;
  let totalDiscountPct = 0;
  let couponPct = 0;
  let memberPct = 0;
  let licensedPct = 0;
  let licensedZurichPct = 0;
  let licensedDFCFPct = 0;
  let discDiff;
  let discountLimit = 30;

  const a1NonDiscountedZurich = a1Object !== null ? a1Object.zurich : 0;
  const a1NonDiscountedDFCF = a1Object !== null ? a1Object.amount - a1Object.zurich : 0;

  const isA1Lonly = zurich_total + dfcf_total - a1NonDiscountedZurich - a1NonDiscountedDFCF === 0 ? true : false;

  // console.log('isA1Lonly', isA1Lonly);
  // console.log('totalDiscountPct before', totalDiscountPct);

  if (!!quote && !!quote.coupon && quote.coupon.value && quote.coupon.value > 0) {
    couponDiscount = (dfcf_total + zurich_total - a1NonDiscountedZurich - a1NonDiscountedDFCF) * (quote.coupon.value / 100);
    couponZurichDiscount = (zurich_total - a1NonDiscountedZurich) * (quote.coupon.value / 100);
    couponDFCFDiscount = (dfcf_total - a1NonDiscountedDFCF) * (quote.coupon.value / 100);
    couponPct = quote.coupon.value;
    totalDiscountPct += quote.coupon.value;
    if (quote.coupon.type && quote.coupon.type === "GIA031") {
      discountLimit = 35;
    }
  }

  if (!!quote) {
    if (totalDiscountPct < discountLimit) {
      discDiff = discountLimit - totalDiscountPct;
      memberPct = discDiff < 30 ? discDiff : 30;
      console.log("quote.advocisMember, Id, advocisMemberValid", quote.advocisMember, quote.memberId, quote.advocisMemberValid);
      if (quote.advocisMember && quote.advocisMember === "yes" && quote.memberId && quote.memberId !== "" && quote.advocisMemberValid === true) {
        memberDiscount = (zurich_total + dfcf_total - a1NonDiscountedZurich - a1NonDiscountedDFCF) * (memberPct / 100);
        console.log("memberDiscount", memberDiscount);
        totalDiscountPct += memberPct;
      }
      if (isA1Lonly) {
        totalDiscountPct = 0;
        memberPct = 0;
      }
    }

    const isCoverageCOnly = quote.unlicensedPlanners && !quote.iiroc && !quote.lifeInsurance ? true : false;

    if (totalDiscountPct < discountLimit) {
      if (quote.firstLicensed && !isCoverageCOnly) {
        discDiff = discountLimit - totalDiscountPct;
        licensedPct = discDiff < 15 ? discDiff : 15;
        let year_diff = moment().diff(quote.firstLicensed, "years");
        if (year_diff <= 3) {
          licensedDiscount = (zurich_total + dfcf_total - a1NonDiscountedZurich - a1NonDiscountedDFCF) * (licensedPct / 100);
          licensedDFCFPct = (dfcf_total - a1NonDiscountedDFCF) * (licensedPct / 100);
          licensedZurichPct = (zurich_total - a1NonDiscountedZurich) * (licensedPct / 100);
          if (!isA1Lonly) {
            totalDiscountPct += licensedPct;
          }
        }
        if (isA1Lonly) {
          licensedPct = 0;
        }
      }
    }
  }

  let totalDiscount = couponDiscount + licensedDiscount + memberDiscount;

  // console.log('totalDiscountPct final', totalDiscountPct);
  // console.log('couponDiscount', couponDiscount);
  // console.log('licensedDiscount', licensedDiscount);
  console.log("memberDiscount", memberDiscount);
  // console.log('totalDiscount', totalDiscount);
  // console.log('couponZurichDiscount', couponZurichDiscount);
  // console.log('couponDFCFDiscount', couponDFCFDiscount);
  // console.log('licensedZurichPct', licensedZurichPct);
  // console.log('licensedDFCFPct', licensedDFCFPct);
  // console.log('licensedPct', licensedPct);
  console.log("memberPct", memberPct);
  // console.log('couponPct', couponPct);

  return {
    totalDiscount,
    couponDiscount,
    couponPct,
    memberPct,
    licensedPct,
    memberDiscount,
    licensedDiscount,
    totalDiscountPct,
    couponDFCFDiscount,
    couponZurichDiscount,
    licensedZurichPct,
    licensedDFCFPct,
  };
};
